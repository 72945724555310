import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi'; // Icono de flecha para retroceder
import Swal from 'sweetalert2'; // Importamos SweetAlert2

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

// Estilos para los campos de entrada
const TextInput = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px; /* Añadir un espacio entre los botones */
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
`;

// Estilos del botón "Enviar Encuesta"
const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;
  opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};

  &:hover {
    background-color: ${({ isLoading }) => (isLoading ? '#FF5C37' : '#ff7043')};
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 8px;
  }
`;

const PrivacyLink = styled.span`
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: #FF5C37;

  &:hover {
    text-decoration: underline;
  }
`;

const FinalStep = ({ onBack, onSubmit, openModal, formData }) => {
  const [nombre, setNombre] = useState('');
  const [telefono, setTelefono] = useState('');
  const [dni, setDni] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para gestionar el cargando
  const formRef = useRef(null); // Añadimos la referencia al formulario

  // Guardar datos en localStorage cuando cambian
  useEffect(() => {
    const savedNombre = localStorage.getItem('nombre');
    const savedTelefono = localStorage.getItem('telefono');
    const savedDni = localStorage.getItem('dni');

    if (savedNombre) setNombre(savedNombre);
    if (savedTelefono) setTelefono(savedTelefono);
    if (savedDni) setDni(savedDni);
  }, []);

  useEffect(() => {
    localStorage.setItem('nombre', nombre);
  }, [nombre]);

  useEffect(() => {
    localStorage.setItem('telefono', telefono);
  }, [telefono]);

  useEffect(() => {
    localStorage.setItem('dni', dni);
  }, [dni]);

  // Validación y envío
  const handleSubmit = () => {
    if (isLoading) return;

    let errorMessage = '';
    if (!nombre) errorMessage = 'El campo de nombre es obligatorio.';
    else if (!telefono) errorMessage = 'El campo de teléfono es obligatorio.';
    else if (!dni) errorMessage = 'El campo de DNI o Documento es obligatorio.';

    if (errorMessage) {
      Swal.fire({
        icon: 'warning',
        title: errorMessage,
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      setIsLoading(true);
      onSubmit({ nombre, telefono, dni });
    }
  };

  return (
    <StepContainer ref={formRef}>
      <h3>Para participar en el sorteo, por favor, deja tus datos de contacto</h3>

      <TextInput
        type="text"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        placeholder="Nombre completo"
      />
      <TextInput
        type="tel"
        value={telefono}
        onChange={(e) => setTelefono(e.target.value)}
        placeholder="Teléfono"
      />
      <TextInput
        type="number" // Cambiado a tipo número
        value={dni}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value)) { // Validar que solo se introduzcan números
            setDni(value);
          }
        }}
        placeholder="DNI o Documento"
      />

      <p>
        Al enviar, aceptas nuestras{' '}
        <PrivacyLink onClick={openModal}>Políticas de Privacidad</PrivacyLink>.
      </p>

      <ButtonContainer>
        <BackButton onClick={onBack}>
          <FiArrowLeft />
        </BackButton>
        <SubmitButton onClick={handleSubmit} isLoading={isLoading}>
          {isLoading ? 'Enviando...' : 'Enviar Encuesta'}
        </SubmitButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default FinalStep;