import React, { useState } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi'; // Asegúrate de importar el ícono de la flecha
import Swal from 'sweetalert2'; // Importamos SweetAlert2

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

// Estilos para cada opción
const OptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Círculo para la selección de la opción
const OptionCircle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.selected ? '#FF5C37' : '#999')};
  background-color: ${(props) => (props.selected ? '#FF5C37' : 'transparent')};
  color: ${(props) => (props.selected ? '#fff' : '#FF5C37')};
  margin-right: 15px;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
`;

// Estilos del texto de la opción
const OptionText = styled.span`
  text-align: left;
  flex-grow: 1;
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px; /* Añadir un espacio entre los botones */
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

// Estilos del botón "Siguiente"
const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

const Step4 = ({ onNext, onBack, currentStep, savedSelection }) => {
  const [selectedStaff, setSelectedStaff] = useState(savedSelection || []);

  // Opciones disponibles
  const staffOptions = [
    'Katty',
    'Carolina',
    'Gabriela',
    'Paola',
    'Wendy',
    'Deylis',
    'Jully',
    'Delia',
    'Jessica'
  ];

  // Manejar selección de opción
  const handleOptionChange = (staff) => {
    setSelectedStaff(prev => {
      if (prev.includes(staff)) {
        return prev.filter(item => item !== staff);
      } else {
        return [...prev, staff];
      }
    });
  };

  // Validación y envío
  const handleSubmit = () => {
    if (selectedStaff.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecciona al menos un miembro del staff',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      onNext(true, selectedStaff); // Pasar la selección al siguiente paso
    }
  };

  return (
    <StepContainer>
      <h3>{currentStep}. ¿Quién de nuestro staff te atendió la última vez? (Puedes seleccionar varios)</h3>
      {staffOptions.map((staff, index) => (
        <OptionItem key={index} onClick={() => handleOptionChange(staff)}>
          <OptionCircle selected={selectedStaff.includes(staff)}>
            {index + 1}
          </OptionCircle>
          <OptionText>{staff}</OptionText>
        </OptionItem>
      ))}

      {/* Contenedor con botón "Anterior" y botón "Siguiente" */}
      <ButtonContainer>
        <BackButton onClick={() => onBack(selectedStaff)}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <NextButton onClick={handleSubmit}>Siguiente</NextButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default Step4;